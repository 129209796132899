

export const BrandData = [
  {
    id: 1,
    name: 'FastTrack',
  },
  {
    id: 2,
    name: 'Titan',
  },
  {
    id: 3,
    name: 'Casio',
  },
  {
    id: 4,
    name: 'Sonata',
  },
];
export const ProductColors = [
  {
    color: '#cc9966',
    color_name: 'Brown'
},
{
    color: '#3399cc',
    color_name: 'Blue'
},
{
    color: '#9966cc',
    color_name: 'Purple'
},
{
    color: '#333333',
    color_name: 'Black'
},
{
    color: '#669933',
    color_name: 'Green'
},
{
    color: '#cc3333',
    color_name: 'Red'
},
{
    color: '#999999',
    color_name: 'Grey'
}

];

export const IdealFor = [
  {
    id: 1,
    name: 'Couple',
  },
  {
    id: 2,
    name: 'Men',
  },
  {
    id: 3,
    name: 'Women',
  },
  {
    id: 4,
    name: 'ALL',
  },
];

export const addresses = [
  {
    id: 1,
    name: 'ShopIn User',
    mobile: '+1 508-966-0591',
    addressLine: '777 Brockton Avenue',
    city: 'Abington MA',
    pin: '2351',
  },
  {
    id: 2,
    name: 'ShopIn User',
    mobile: '+1 508-966-0592',
    addressLine: '250 Hartford Avenue',
    city: 'Bellingham MA',
    pin: '2322',
  },
  {
    id: 3,
    name: 'ShopIn User',
    mobile: '+1 508-966-0593',
    addressLine: '700 Oak Street',
    city: 'Brockton MA',
    pin: '2301',
  },
];

export const DiscountList = [
  {
 slug:"XS",
 name:"Extra Small",

  },
  {
    slug: "S",
    name: 'Small',
  },
  {
    slug: "M",
    name: 'Medium',
  },
  {
    slug: "L",
    name: 'Large',
  },
  {
    slug: "XL",
    name: 'Extra Large',
  },
  
];

export const cartItems = [
  {
    id: 1,
    title: "Mens's Exclusive Watch",
    mrp: '120',
    discount: '25',
    brand: 'FastTrack',
    image: '/assets/images/ecommerce/watch1.png',
    count: 1,
  },
  {
    id: 2,
    title: 'HP Pavilion Gaming Ryzen 7 Octa Core 4800H',
    mrp: '1250',
    discount: '20',
    brand: 'FastTrack',
    image: '/assets/images/ecommerce/item-2.png',
    count: 1,
  },
  {
    id: 3,
    title: 'NIKON Z6 II Body Mirrorless Camera with 64GB SD Card',
    mrp: '1199',
    discount: '30',
    brand: 'FastTrack',
    image: '/assets/images/ecommerce/item-4.png',
    count: 1,
  },
];

const ecommerceData = [
  {
    id: 2,
    title: 'HP Pavilion Gaming Ryzen 7 Octa Core 4800H',
    description:
      'Microsoft Office 2019 Home and Student, HP Documentation, HP e-service, HP BIOS Recovery, HP SSRM, HP Smart, HP Jumpstarts, Dropbox, HP Audio Switch, HP Audio Boost',
    mrp: '1250',
    brand: 4,
    ideaFor: 3,
    discount: '20',
    rating: 4,
    color: ProductColors.PINK,
    reviews: 4356,
    image: [
      {
        id: 3,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 43,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 23,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 54,
        src: '/assets/images/ecommerce/item-2.png',
      },
    ],
  },
  {
    id: 3,
    title: 'NIKON Z6 II Body Mirrorless Camera with 64GB SD Card',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '1199',
    ideaFor: 4,
    brand: 1,
    discount: '30',
    rating: 4,
    color: ProductColors.BLUE,
    reviews: 235,
    image: [
      {
        id: 4343,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 323243,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 325432,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 653323,
        src: '/assets/images/ecommerce/item-4.png',
      },
    ],
  },
  {
    id: 4,
    title:
      'SONY WH-CH510 Google Assistant enabled Bluetooth Headset  (Black, On the Ear)',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '100',
    discount: '35',
    rating: 4,
    ideaFor: 2,
    brand: 4,
    color: ProductColors.PINK,
    reviews: 4323,
    image: [
      {
        id: 4343443,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 324324,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 655434,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 243443,
        src: '/assets/images/ecommerce/item-3.png',
      },
    ],
  },
  {
    id: 5,
    title: 'APPLE iPhone 12 (Blue, 64 GB)',
    description:
      'Super Retina XDR Display, 6.1 inch (Diagonal) All Screen OLED Display, HDR Display, True Tone, Wide Colour (P3), Haptic Touch, 2000000:1 Contrast Ratio (Typical)',
    mrp: '820',
    ideaFor: 1,
    brand: 1,
    discount: '25',
    rating: 3,
    color: ProductColors.GREY,
    reviews: 2000,
    image: [
      {
        id: 43434345,
        src: '/assets/images/ecommerce/item-1.png',
      },
      {
        id: 44343,
        src: '/assets/images/ecommerce/item-1.png',
      },
      {
        id: 5654443,
        src: '/assets/images/ecommerce/item-1.png',
      },
      {
        id: 433343,
        src: '/assets/images/ecommerce/item-1.png',
      },
    ],
  },
  {
    id: 6,
    title: 'HP Pavilion Gaming Ryzen 7 Octa Core 4800H',
    description:
      'Microsoft Office 2019 Home and Student, HP Documentation, HP e-service, HP BIOS Recovery, HP SSRM, HP Smart, HP Jumpstarts, Dropbox, HP Audio Switch, HP Audio Boost',
    mrp: '1250',
    discount: '20',
    ideaFor: 3,
    brand: 3,
    rating: 3,
    color: ProductColors.LIGHT_PINK,
    reviews: 4356,
    image: [
      {
        id: 4354,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 2343,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 233,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 544,
        src: '/assets/images/ecommerce/item-2.png',
      },
    ],
  },
  {
    id: 7,
    title: 'NIKON Z6 II Body Mirrorless Camera with 64GB SD Card',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '1199',
    ideaFor: 4,
    brand: 2,
    discount: '30',
    rating: 4,
    color: ProductColors.PINK,
    reviews: 235,
    image: [
      {
        id: 4344343,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 32443243,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 32544432,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 654333323,
        src: '/assets/images/ecommerce/item-4.png',
      },
    ],
  },
  {
    id: 8,
    title:
      'SONY WH-CH510 Google Assistant enabled Bluetooth Headset  (Black, On the Ear)',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '100',
    discount: '35',
    ideaFor: 2,
    brand: 1,
    rating: 2,
    color: ProductColors.PARROT,
    reviews: 4323,
    image: [
      {
        id: 344,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 4342,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 7654,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 3232,
        src: '/assets/images/ecommerce/item-3.png',
      },
    ],
  },
  {
    id: 9,
    title: 'APPLE iPhone 12 (Blue, 64 GB)',
    description:
      'Super Retina XDR Display, 6.1 inch (Diagonal) All Screen OLED Display, HDR Display, True Tone, Wide Colour (P3), Haptic Touch, 2000000:1 Contrast Ratio (Typical)',
    mrp: '820',
    discount: '25',
    brand: 2,
    ideaFor: 1,
    rating: 5,
    color: ProductColors.PINK,
    reviews: 2000,
    image: [
      {
        id: 34332,
        src: '/assets/images/ecommerce/item-1.png',
      },
      {
        id: 432121554,
        src: '/assets/images/ecommerce/item-1.png',
      },
      {
        id: 42334324,
        src: '/assets/images/ecommerce/item-1.png',
      },
      {
        id: 333245,
        src: '/assets/images/ecommerce/item-1.png',
      },
    ],
  },
  {
    id: 1,
    title: "Mens's Exclusive Watch",
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '120',
    discount: '25',
    rating: 5,
    ideaFor: 1,
    brand: 1,
    color: ProductColors.BLUE,
    reviews: 2000,
    image: [
      {
        id: 3332,
        src: '/assets/images/ecommerce/watch1.png',
      },
      {
        id: 43554,
        src: '/assets/images/ecommerce/watch1.png',
      },
      {
        id: 434324,
        src: '/assets/images/ecommerce/watch1.png',
      },
      {
        id: 33245,
        src: '/assets/images/ecommerce/watch1.png',
      },
    ],
  },
  {
    id: 10,
    title: 'HP Pavilion Gaming Ryzen 7 Octa Core 4800H',
    description:
      'Microsoft Office 2019 Home and Student, HP Documentation, HP e-service, HP BIOS Recovery, HP SSRM, HP Smart, HP Jumpstarts, Dropbox, HP Audio Switch, HP Audio Boost',
    mrp: '1250',
    discount: '20',
    ideaFor: 2,
    brand: 3,
    rating: 4,
    color: ProductColors.LIGHT_PINK,
    reviews: 4356,
    image: [
      {
        id: 32222,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 3333,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 4444,
        src: '/assets/images/ecommerce/item-2.png',
      },
      {
        id: 5555,
        src: '/assets/images/ecommerce/item-2.png',
      },
    ],
  },
  {
    id: 11,
    title: 'NIKON Z6 II Body Mirrorless Camera with 64GB SD Card',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '1199',
    discount: '30',
    rating: 3,
    ideaFor: 3,
    brand: 2,
    color: ProductColors.PARROT,
    reviews: 235,
    image: [
      {
        id: 43333332343,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 323232,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 54554,
        src: '/assets/images/ecommerce/item-4.png',
      },
      {
        id: 4343232,
        src: '/assets/images/ecommerce/item-4.png',
      },
    ],
  },
  {
    id: 12,
    title:
      'SONY WH-CH510 Google Assistant enabled Bluetooth Headset  (Black, On the Ear)',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '100',
    discount: '35',
    rating: 4,
    ideaFor: 2,
    color: ProductColors.GREY,
    reviews: 4323,
    brand: 3,
    image: [
      {
        id: 43343443,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 333224324,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 5545454,
        src: '/assets/images/ecommerce/item-3.png',
      },
      {
        id: 42332323,
        src: '/assets/images/ecommerce/item-3.png',
      },
    ],
  },
];
export const recentOrders = [
  {
    id: '#SK231',
    customer: 'Ina Hughes',
    product: 'HP Pavilion Gaming Ryzen 7 Octa Core 4800H',
    date: '08-21-2020',
    paymentType: 'COD',
    price: '$1250',
    status: 'Cancelled',
  },
  {
    id: '#SK232',
    customer: 'Myrtie Ferguson',
    date: '08-12-2020',
    product: 'Addida Shoes',
    paymentType: 'Prepaid',
    price: '$100',
    status: 'Delivered',
  },
  {
    id: '#SK233',
    customer: 'Johnny Herrera',
    date: '07-30-2020',
    product: 'Sleeve Jacket',
    price: '$1,020',
    paymentType: 'Prepaid',
    status: 'Pending',
  },
  {
    id: '#SK234',
    customer: 'Ina Hughes',
    product: "Mens's Exclusive Watch",
    date: '08-21-2020',
    paymentType: 'COD',
    price: '$125',
    status: 'Cancelled',
  },
  {
    id: '#SK235',
    customer: 'Myrtie Ferguson',
    date: '08-12-2020',
    product: 'Addida Shoes',
    paymentType: 'Prepaid',
    price: '$100',
    status: 'Delivered',
  },
  {
    id: '#SK236',
    customer: 'Johnny Herrera',
    date: '07-30-2020',
    product: 'Sleeve Jacket',
    price: '$1,020',
    paymentType: 'Prepaid',
    status: 'Pending',
  },
  {
    id: '#SK2317',
    customer: 'Ina Hughes',
    product: 'Bicycle',
    date: '08-21-2020',
    paymentType: 'COD',
    price: '$125',
    status: 'Cancelled',
  },
  {
    id: '#SK2328',
    customer: 'Myrtie Ferguson',
    date: '08-12-2020',
    product: 'Addida Shoes',
    paymentType: 'Prepaid',
    price: '$100',
    status: 'Delivered',
  },
  {
    id: '#SK2393',
    customer: 'Johnny Herrera',
    date: '07-30-2020',
    product: 'Sleeve Jacket',
    price: '$1,020',
    paymentType: 'Prepaid',
    status: 'Pending',
  },
  {
    id: '#SK2319',
    customer: 'Ina Hughes',
    product: 'Bicycle',
    date: '08-21-2020',
    paymentType: 'COD',
    price: '$125',
    status: 'Cancelled',
  },
  {
    id: '#SK2323',
    customer: "Mens's Exclusive Watch",
    date: '08-12-2020',
    product: 'Addida Shoes',
    paymentType: 'Prepaid',
    price: '$100',
    status: 'Delivered',
  },
  {
    id: '#SK2333',
    customer: 'Johnny Herrera',
    date: '07-30-2020',
    product: 'Sleeve Jacket',
    price: '$1,020',
    paymentType: 'Prepaid',
    status: 'Pending',
  },
  {
    id: '#SK2331',
    customer: 'Ina Hughes',
    product: 'Bicycle',
    date: '08-21-2020',
    paymentType: 'COD',
    price: '$125',
    status: 'Cancelled',
  },
  {
    id: '#SK2232',
    customer: 'Myrtie Ferguson',
    date: '08-12-2020',
    product: 'Addida Shoes',
    paymentType: 'Prepaid',
    price: '$100',
    status: 'Delivered',
  },
  {
    id: '#SK2343',
    customer: 'Johnny Herrera',
    date: '07-30-2020',
    product: 'Sleeve Jacket',
    price: '$1,020',
    paymentType: 'Prepaid',
    status: 'Pending',
  },
  {
    id: '#SK2318',
    customer: 'Ina Hughes',
    product: 'Bicycle',
    date: '08-21-2020',
    paymentType: 'COD',
    price: '$125',
    status: 'Cancelled',
  },
  {
    id: '#SK2329',
    customer: 'Myrtie Ferguson',
    date: '08-12-2020',
    product: 'Addida Shoes',
    paymentType: 'Prepaid',
    price: '$100',
    status: 'Delivered',
  },
  {
    id: '#SK2379',
    customer: 'Johnny Herrera',
    date: '07-30-2020',
    product: 'Sleeve Jacket',
    price: '$1,020',
    paymentType: 'Prepaid',
    status: 'Pending',
  },
];

export const customersData = [
  {
    id: 1,
    name: 'Johnny Herrera',
    email: 'johnny.herrera@exmaple.com',
    lastItem: 'Sleeve Jacket',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$1,020',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 2,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 3,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 4,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 5,
    name: 'Johnny Herrera',
    email: 'johnny.herrera@exmaple.com',
    lastItem: 'Sleeve Jacket',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$1,020',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 6,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 7,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 8,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 9,
    name: 'Johnny Herrera',
    email: 'johnny.herrera@exmaple.com',
    lastItem: 'Sleeve Jacket',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$1,020',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 10,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 11,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 12,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 13,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 14,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 15,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 16,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 17,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 18,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 19,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 20,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 21,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 22,
    name: 'Myrtie Ferguson',
    email: 'myrtie.ferguson@exmaple.com',
    lastItem: 'Addida Shoes',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
  {
    id: 23,
    name: 'Josh Rash',
    email: 'josh@exmaple.com',
    lastItem: 'Apple Watch',
    lastOrder: '26 Aug',
    rating: '4.6',
    balance: '$125',
    address: '675 Grove Street Bethpage, NY 11714',
    joinDate: '23 Jan, 2015',
  },
];
export default ecommerceData;
