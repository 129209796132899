const reactSlickData = {
  profileSlide: [
    {
      id: 1,
      srcImg: '/assets/images/avatar/A5.jpg',
      name: 'Talan Phips',
      designation: 'Heart Specialist',
      years: 24,
      blood: 'A+',
      height: 185,
      weight: 84,
    },
    {
      id: 2,
      srcImg: '/assets/images/avatar/A4.jpg',
      name: 'Talan Phips',
      designation: 'Heart Specialist',
      years: 21,
      blood: 'A+',
      height: 180,
      weight: 74,
    },
    {
      id: 3,
      srcImg: '/assets/images/avatar/A8.jpg',
      name: 'Talan Phips',
      designation: 'Heart Specialist',
      years: 20,
      blood: 'A+',
      height: 155,
      weight: 64,
    },
  ],
  slideBasic: [
    {
      id: 3,
      srcImg: '/assets/images/slick-slider/carousel-3.jpg',
      title: 'Slide Basic',
    },
    {
      id: 4,
      srcImg: '/assets/images/slick-slider/carousel-5.jpg',
      title: 'Slide Basic',
    },
    {
      id: 1,
      srcImg: '/assets/images/slick-slider/carousel-1.jpg',
      title: 'Slide Basic',
    },
    {
      id: 2,
      srcImg: '/assets/images/slick-slider/carousel-2.jpg',
      title: 'Slide Basic',
    },
    {
      id: 5,
      srcImg: '/assets/images/slick-slider/carousel-6.jpg',
      title: 'Slide Basic',
    },
  ],
  slideBasicArrow: [
    {
      id: 2,
      srcImg: '/assets/images/slick-slider/carousel-2.jpg',
      title: 'Slide Basic',
    },
    {
      id: 3,
      srcImg: '/assets/images/slick-slider/carousel-3.jpg',
      title: 'Slide Basic',
    },
    {
      id: 4,
      srcImg: '/assets/images/slick-slider/carousel-5.jpg',
      title: 'Slide Basic',
    },
    {
      id: 1,
      srcImg: '/assets/images/slick-slider/carousel-1.jpg',
      title: 'Slide Basic',
    },
    {
      id: 5,
      srcImg: '/assets/images/slick-slider/carousel-6.jpg',
      title: 'Slide Basic',
    },
  ],
  slideBasicTwo: [
    {
      id: 1,
      srcImg: '/assets/images/slick-slider/carousel-1.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 3,
      srcImg: '/assets/images/slick-slider/carousel-3.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 4,
      srcImg: '/assets/images/slick-slider/carousel-5.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 2,
      srcImg: '/assets/images/slick-slider/carousel-2.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 5,
      srcImg: '/assets/images/slick-slider/carousel-6.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
  ],
  slideBasicThree: [
    {
      id: 1,
      srcImg: '/assets/images/slick-slider/carousel-1.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    },
    {
      id: 4,
      srcImg: '/assets/images/slick-slider/carousel-5.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    },
    {
      id: 3,
      srcImg: '/assets/images/slick-slider/carousel-3.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    },
    {
      id: 2,
      srcImg: '/assets/images/slick-slider/carousel-2.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    },
    {
      id: 5,
      srcImg: '/assets/images/slick-slider/carousel-6.jpg',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    },
  ],
  slideBasicFour: [
    {
      id: 1,
      srcImg: '/assets/images/slick-slider/carousel-1.jpg',
      title: 'Creative artwork on world.',
      avatar: '/assets/images/avatar/A5.jpg',
      avatarName: 'Talan Phips',
      data: '16 Oct 2021',
      description:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    },
    {
      id: 3,
      srcImg: '/assets/images/slick-slider/carousel-3.jpg',
      title: 'Creative artwork on world.',
      avatar: '/assets/images/avatar/A5.jpg',
      avatarName: 'Talan Phips',
      data: '16 Oct 2021',
      description:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    },
    {
      id: 4,
      srcImg: '/assets/images/slick-slider/carousel-5.jpg',
      title: 'Creative artwork on world.',
      avatar: '/assets/images/avatar/A5.jpg',
      avatarName: 'Talan Phips',
      data: '16 Oct 2021',
      description:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    },
    {
      id: 2,
      srcImg: '/assets/images/slick-slider/carousel-2.jpg',
      title: 'Creative artwork on world.',
      avatar: '/assets/images/avatar/A5.jpg',
      avatarName: 'Talan Phips',
      data: '16 Oct 2021',
      description:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    },
  ],
  slideBasicFive: [
    {
      id: 1,
      srcImg: '/assets/images/slick-slider/carousel-1.jpg',
      srcThumb: '/assets/images/wall/happen_img2.png',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 3,
      srcImg: '/assets/images/slick-slider/carousel-3.jpg',
      srcThumb: '/assets/images/wall/happen_img3.png',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 4,
      srcImg: '/assets/images/slick-slider/carousel-5.jpg',
      srcThumb: '/assets/images/wall/happen_img1.png',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 2,
      srcImg: '/assets/images/slick-slider/carousel-2.jpg',
      srcThumb: '/assets/images/wall/happen_img2.png',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
    {
      id: 5,
      srcImg: '/assets/images/slick-slider/carousel-6.jpg',
      srcThumb: '/assets/images/wall/happen_img4.png',
      title:
        "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
      description:
        'Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.',
    },
  ],
};

export default reactSlickData;
