// import React from 'react';
// import {RoutePermittedRole} from 'shared/constants/AppConst';

// const ContactUs = React.lazy(() => import('./ContactUs'));

export const extraPagesConfigs = [
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/extra-pages/contact-us',
  //   element: <ContactUs />,
  // },
];
