export const authRole = {
  admin: [
    'analytics',
    'CRM',
    'home_page',
    'my_profile',
    'add_employee',
    'add_selling_point',
    'show_employee',
    'show_employee_old',
    'show_customer',
    'show_selling_point_old',
    'show_selling_point',
    'running_cost',
    'running_cost_deleted',
    'all_orders',
    'canceled_orders',
    'all_order_detials',
    'in_progress_orders',
    'pending_orders',
    'in_delivery_orders',
    'complated_orders',
    'reports',
    'add_orders',
    'add_product',
    'edit_products',
    'show_products',
    'show_product_details',
    'add_show_damaged_product',
    'add_edit_Delete_website_management_coupon',
    'add_edit_Delete_website_management_bannars',
  ],
  desginer: ['add_edit_Delete_website_management_bannars', 'my_profile'],
  data_entry: [
    'add_product',
    'edit_products',
    'show_products',
    'add_show_damaged_product',
  ],
  accounting_manager: [
    'my_profile',
    'home_page',
    'running_cost',
    'running_cost_deleted',
    'reports',
    'complated_orders',
  ],
  call_support: [
    'my_profile',
    'add_orders',
    'system_manager',
    'show_customer',
    'all_orders',
    'all_order_detials',
    'in_progress_orders',
    'pending_orders',
    'in_delivery_orders',
    'complated_orders',
    'canceled_orders',
    'show_products',
  ],
  warehouse_employee: [
    'my_profile',
    'all_order_detials',
    'in_progress_orders',
    'canceled_orders',
    'add_product',
    'show_products',
    'show_product_details',
    'edit_products',
    'add_show_damaged_product',
  ],
  delivery_employee: ['my_profile', 'in_delivery_orders'],
  sales_man: [
    'CRM',
    'my_profile',
    // 'add_orders',
    // 'add_selling_point',
    'show_selling_point',
    'show_products',
    'show_product_details',
    // 'edit_products',
  ],
  developer: [
    'analytics',
    'CRM',
    'home_page',
    'my_profile',
    'system_manager',
    'add_employee',
    'add_selling_point',
    'show_employee',
    'show_employee_old',
    'show_customer',
    'show_selling_point',
    'show_selling_point_old',
    'running_cost',
    'running_cost_deleted',
    'all_orders',
    'all_order_detials',
    'in_progress_orders',
    'pending_orders',
    'in_delivery_orders',
    'complated_orders',
    'canceled_orders',
    'add_orders',
    'edit_products',
    'add_product',
    'show_products',
    'show_product_details',
    'add_show_damaged_product',
    'reports',
    'add_edit_Delete_website_management_coupon',
    'add_edit_Delete_website_management_bannars',
  ],
  human_resources: [
    'my_profile',
    'add_employee',
    'show_employee',
    'show_employee_old',
    'show_customer',
    'add_edit_Delete_website_management_coupon',
  ],
};

export const RoutePermittedRole = {
  my_profile: 'my_profile',
  // _______________________mainPages _____________________
  home_page: 'home_page',
  CRM: 'CRM',
  analytics: 'analytics',
  // _______________________developer _____________________
  system_manager: 'system_manager',
  // _______________________management _____________________
  add_employee: 'add_employee',
  add_selling_point: 'add_selling_point',
  show_employee: 'show_employee',
  show_employee_old: 'show_employee_old',
  show_customer: 'show_customer',
  show_selling_point: 'show_selling_point',
  show_selling_point_old: 'show_selling_point_old',
  running_cost: 'running_cost',
  running_cost_deleted: 'running_cost_deleted',
  // _______________________orders _____________________
  all_orders: 'all_orders',
  all_order_detials: 'all_order_detials',
  in_progress_orders: 'in_progress_orders',
  pending_orders: 'pending_orders',
  in_delivery_orders: 'in_delivery_orders',
  complated_orders: 'complated_orders',
  canceled_orders: 'canceled_orders',
  add_orders: 'add_orders',
  // _______________________products_____________________
  add_product: 'add_product',
  edit_products: 'edit_products',
  show_products: 'show_products',
  show_product_details: 'show_product_details',
  add_show_damaged_product: 'add_show_damaged_product',
  // _______________________reports_____________________
  reports: 'reports',
  // _______________________website_____________________
  add_edit_Delete_website_management_coupon:
    'add_edit_Delete_website_management_coupon',
  add_edit_Delete_website_management_bannars:
    'add_edit_Delete_website_management_bannars',
};
export const defaultUser = {
  displayName: 'Shopinps Admin',
  email: 'info@shopinps.com',
  token: 'access-token',
  role: 'admin',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const initialUrl = '/dashboards/homePage'; // this url will open after login
