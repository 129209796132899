import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppConst';

const Products = React.lazy(() => import('./Products'));
const HiddenProducts = React.lazy(() => import('./HiddenProducts'));
const SoldoutProducts = React.lazy(() => import('./SoldoutProducts'));
const SalesProducts = React.lazy(() => import('./SalesProducts'));
const EventsSalesProducts = React.lazy(() => import('./EventsSalesProducts'));

const ProductDetail = React.lazy(() => import('./ProductDetail'));
const AddProductForm = React.lazy(() => import('./AddProductForm'));
const EditProductForm = React.lazy(() => import('./EditProductForm'));
const ReportDamagedProduct = React.lazy(() => import('./ReportDamagedProduct'));

export const productManagementConfig = [
  {
    permittedRole: RoutePermittedRole.add_product,
    path: '/product-management/AddProductForm',
    element: <AddProductForm />,
  },
  {
    permittedRole: RoutePermittedRole.edit_products,
    path: '/product-management/EditProductForm/:id',
    element: <EditProductForm />,
  },
  {
    permittedRole: RoutePermittedRole.show_products,
    path: '/product-management/products',
    element: <Products />,
  },
  {
    permittedRole: RoutePermittedRole.show_products,
    path: '/product-management/HiddenProducts',
    element: <HiddenProducts />,
  },
  {
    permittedRole: RoutePermittedRole.show_products,
    path: '/product-management/SalesProducts',
    element: <SalesProducts />,
  },
  {
    permittedRole: RoutePermittedRole.show_products,
    path: '/product-management/EventsSalesProducts',
    element: <EventsSalesProducts />,
  },
  {
    permittedRole: RoutePermittedRole.show_products,
    path: '/product-management/SoldoutProducts',
    element: <SoldoutProducts />,
  },
  {
    permittedRole: RoutePermittedRole.show_product_details,
    path: [
      '/product-management/product_detail/',
      '/product-management/product_detail/:id',
    ],
    element: <ProductDetail />,
  },
  {
    permittedRole: RoutePermittedRole.add_show_damaged_product,
    path: ['/product-management/damaged-product'],
    element: <ReportDamagedProduct />,
  },
];
