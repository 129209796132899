import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppConst';

const FinancialReports = React.lazy(() => import('./financialReports'));
const OrderReports = React.lazy(() => import('./orderReports'));
const EmployeesReports = React.lazy(() => import('./employeesReports'));
const WarehouseReports = React.lazy(() => import('./warehouseReports'));
const WarehouseVendorReports = React.lazy(() => import('./warehouseVendorReports'));

const RunningCostReports = React.lazy(() => import('./runningCostReports'));

export const reportsConfig = [
  {
    permittedRole: RoutePermittedRole.reports,
    path: '/reports/financialReports',
    element: <FinancialReports />,
  },
  {
    permittedRole: RoutePermittedRole.reports,
    path: '/reports/orderReports',
    element: <OrderReports />,
  },
  {
    permittedRole: RoutePermittedRole.reports,
    path: '/reports/employeesreports',
    element: <EmployeesReports />,
  },
  {
    permittedRole: RoutePermittedRole.reports,
    path: '/reports/warehousereports',
    element: <WarehouseReports />,
  },
  {
    permittedRole: RoutePermittedRole.reports,
    path: '/reports/warehouseVendorReports',
    element: <WarehouseVendorReports />,
  },
  {
    permittedRole: RoutePermittedRole.reports,
    path: '/reports/runningCostReports',
    element: <RunningCostReports />,
  },
];
