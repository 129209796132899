import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppConst';
const OrderDetials = React.lazy(() => import('./orderDetials'));

const InProgressOrders = React.lazy(() => import('../Orders/InProgressOrders'));
const Orders = React.lazy(() => import('../Orders/Orders'));
const InDeliveryOrders = React.lazy(() => import('../Orders/InDeliveryOrders'));
const CompletedOrders = React.lazy(() => import('../Orders/CompletedOrders'));
const CanceledOrders = React.lazy(() => import('../Orders/CanceledOrders'));
const AllOrders = React.lazy(() => import('../Orders/AllOrders'));
const AddOrders = React.lazy(() => import('../Orders/AddOrder'));
const EditOrder = React.lazy(() => import('../Orders/EditOrder'));
const DeliveredOrders = React.lazy(() => import('../Orders/DeliveredOrders'));
const ReadyToDeliveryOrders = React.lazy(() => import('../Orders/ReadyToDeliveryOrders'));
const InReviewOrders = React.lazy(() => import('../Orders/InReviewOrders'));

export const ordersConfig = [
  {
    permittedRole: RoutePermittedRole.all_orders,
    path: '/Orders/AllOrders',
    element: <AllOrders />,
  },
  {
    permittedRole: RoutePermittedRole.all_order_detials,
    path: ['/Orders/orderDetials/:id', '/Orders/orderDetials/'],
    element: <OrderDetials />,
  },
  {
    permittedRole: RoutePermittedRole.all_order_detials,
    path: ['/Orders/EditOrder/:id', '/Orders/EditOrder/'],
    element: <EditOrder />,
  },
  {
    permittedRole: RoutePermittedRole.in_progress_orders,
    path: '/Orders/InProgressOrders',
    element: <InProgressOrders />,
  },

  {
    permittedRole: RoutePermittedRole.pending_orders,
    path: '/Orders/Orders',
    element: <Orders />,
  },
  {
    permittedRole: RoutePermittedRole.complated_orders,
    path: '/Orders/ReadyToDeliveryOrders',
    element: <ReadyToDeliveryOrders />,
  },
  {
    permittedRole: RoutePermittedRole.in_delivery_orders,
    path: '/Orders/InDeliveryOrders',
    element: <InDeliveryOrders />,
    
  },
  {
    permittedRole: RoutePermittedRole.complated_orders,
    path: '/Orders/DeliveredOrders',
    element: <DeliveredOrders />,
  },

  {
    permittedRole: RoutePermittedRole.complated_orders,
    path: '/Orders/CompletedOrders',
    element: <CompletedOrders />,
  },
  {
    permittedRole: RoutePermittedRole.canceled_orders,
    path: '/Orders/InReviewOrders',
    element: <InReviewOrders />,
  },
  {
    permittedRole: RoutePermittedRole.canceled_orders,
    path: '/Orders/CanceledOrders',
    element: <CanceledOrders />,
  },
  {
    permittedRole: RoutePermittedRole.add_orders,
    path: '/Orders/AddOrder',
    element: <AddOrders />,
  },
];
