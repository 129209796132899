import saMessages from '../locales/ar_SA.json';
import {arSD} from '@mui/material/locale';
const saLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: arSD,
  locale: 'ar-SA',
};
export default saLang;
