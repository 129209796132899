// import React from 'react';
// import {RoutePermittedRole} from '../../shared/constants/AppConst';

// const Checkout = React.lazy(() => import('./Checkout'));
// const Carts = React.lazy(() => import('./Carts'));
// const Confirmation = React.lazy(() => import('./Confirmation'));
// const Invoice1 = React.lazy(() => import('./Invoice1'));
// const Invoice2 = React.lazy(() => import('./Invoice2'));

export const ecommerceConfig = [
  // {
  //   permittedRole: RoutePermittedRole.system_manager,
  //   path: '/ecommerce/invoice-1',
  //   element: <Invoice1 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.system_manager,
  //   path: '/ecommerce/invoice-2',
  //   element: <Invoice2 />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.system_manager,
  //   path: '/ecommerce/checkout',
  //   element: <Checkout />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/ecommerce/cart',
  //   element: <Carts />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/ecommerce/confirmation',
  //   element: <Confirmation />,
  // },
];
