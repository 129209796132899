const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
  },
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'عربي',
  },
];
export default languageData;
