import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppConst';

const Banners = React.lazy(() => import('./Banners'));
const Copoun = React.lazy(() => import('./Copoun'));
const MainCategory = React.lazy(() => import('./MainCategory'));
const CategoryDetials =React.lazy(()=>import('./CategoryDetials'));
const WebsiteHomePageSetting = React.lazy(() => import('./websiteHomePageSetting'));
const ProductsPageSetting = React.lazy(() => import('./ProductsPageSetting'));
const FinancialMangmentNotif = React.lazy(() => import('./financialMangmentNotif'));

export const websiteManagementConfig = [
  {
    permittedRole:
      RoutePermittedRole.add_edit_Delete_website_management_bannars,
    path: '/websiteManagement/Banners',
    element: <Banners />,
  },
  {
    permittedRole: RoutePermittedRole.add_edit_Delete_website_management_coupon,
    path: '/websiteManagement/Copoun',
    element: <Copoun />,
  },
  {
    permittedRole:
      RoutePermittedRole.add_edit_Delete_website_management_bannars,
    path: '/websiteManagement/MainCategory',
    element: <MainCategory />,
  },
  {
    permittedRole:
      RoutePermittedRole.add_edit_Delete_website_management_bannars,
    path: 'notifications/financialMangment',
    element: <FinancialMangmentNotif />,
  },
  {
    permittedRole:
      RoutePermittedRole.add_edit_Delete_website_management_bannars,
    path: '/websiteManagement/websiteHomePageSetting',
    element: <WebsiteHomePageSetting />,
  },
  {
    permittedRole:
      RoutePermittedRole.add_edit_Delete_website_management_bannars,
    path: '/websiteManagement/ProductsPageSetting',
    element: <ProductsPageSetting />,
  },
  {
    permittedRole:
      RoutePermittedRole.add_edit_Delete_website_management_bannars,
      path: ['/websiteManagement/CategoryDetials/:id', '/websiteManagement/CategoryDetials/'],
    element: <CategoryDetials />,
  },
];

