import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppConst';

const Customers = React.lazy(() => import('./Customers'));
const Checks = React.lazy(() => import('./Checks'));
const ChecksReport = React.lazy(() => import('./ChecksReport'));

const Debtors = React.lazy(() => import('./Debters'));
const AddDebtor = React.lazy(() => import('./Debters/AddDebtor'));
const AddPayment = React.lazy(() => import('./Debters/DebtorsDetails/Payemnts/addPayment'));
const CheckDetails = React.lazy(() => import('./Debters/DebtorsDetails/Checks/CheckDetails'));
const EditDebtor = React.lazy(() => import('./Debters/EditDebtor'));

const CustomerDetials = React.lazy(() => import('./CustomerDetials'));
const DebtorsDetails = React.lazy(() => import('./Debters/DebtorsDetails'));

const Employee = React.lazy(() => import('./Employees'));
const OldEmployee = React.lazy(() => import('./oldEmployee'));
const Transaction = React.lazy(()=>import('./Transaction'));
const TransactionSuccess = React.lazy(()=>import('./TransactionSuccess'));

const RunningCost = React.lazy(() => import('./RunningCost'));
const AddEmployee = React.lazy(() => import('./Employees/AddEmployee'));
const AddSellingpoint = React.lazy(() =>
  import('./Sellingpoint/AddSellingpoint'),
);
const OldSellingpoint = React.lazy(() => import('./OldSellingpoint'));

const DletedRuningCost = React.lazy(() => import('./DeletedRunningCost'));
const Sellingpoint = React.lazy(() => import('./Sellingpoint'));
const Mail  = React.lazy(() => import('./Mail'));
const ReadedMail  = React.lazy(() => import('./ReadedMail'));
export const managementConfig = [
  {
    permittedRole: RoutePermittedRole.add_employee,
    path: '/management/employees/AddEmployee',
    element: <AddEmployee />,
  },
  {
    permittedRole: RoutePermittedRole.add_selling_point,
    path: '/management/Sellingpoint/AddSellingpoint',
    element: <AddSellingpoint />,
  },
  {
    permittedRole: RoutePermittedRole.show_employee,
    path: '/management/employees',
    element: <Employee />,
  },
  {
    permittedRole: RoutePermittedRole.show_employee_old,
    path: '/management/oldEmployee',
    element: <OldEmployee />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/customers',
    element: <Customers />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: ['/management/CustomerDetials/:id'],
    element: <CustomerDetials />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: ['/management/DebtorsDetails/:debtorId'],
    element: <DebtorsDetails />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/debtors',
    element: <Debtors />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/debtors/addPayment/:debtorId',
    element: <AddPayment />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/debtors/AddDebtor',
    element: <AddDebtor />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/debtors/EditDebtor/:debtorId',
    element: <EditDebtor />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/checks',
    element: <Checks />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/ChecksReport',
    element: <ChecksReport />,
  },
  {
    permittedRole: RoutePermittedRole.show_customer,
    path: '/management/Checks/CheckDetails/:debtorId/:checkId',
    element: <CheckDetails />,
  },
  {
    permittedRole: RoutePermittedRole.show_selling_point,
    path: '/management/Sellingpoint',
    element: <Sellingpoint />,
  },
  
  {
    permittedRole: RoutePermittedRole.show_selling_point_old,
    path: '/management/OldSellingpoint',
    element: <OldSellingpoint />,
  },
  {
    permittedRole: RoutePermittedRole.running_cost,
    path: '/management/runningCost',
    element: <RunningCost />,
  },
  {
    permittedRole: RoutePermittedRole.running_cost_deleted,
    path: '/management/deletRuningCost',
    element: <DletedRuningCost />,
  },
  {
    permittedRole: RoutePermittedRole.running_cost,
    path: '/management/Transaction',
    element: <Transaction />,
  },
  {
    permittedRole: RoutePermittedRole.running_cost,
    path: '/management/TransactionSuccess',
    element: <TransactionSuccess />,
  },
  {
    permittedRole: RoutePermittedRole.running_cost,
    path: '/management/Mail',
    element: <Mail />,
  },
  {
    permittedRole: RoutePermittedRole.running_cost,
    path: '/management/ReadedMail',
    element: <ReadedMail />,
  },
];
