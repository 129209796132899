import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppConst';

const FacebookData = React.lazy(() => import('./FacebookData'));


export const marketingSectionReports = [
  {
    permittedRole: RoutePermittedRole.reports,
    path: '/marketing/FacebookData',
    element: <FacebookData />,
  },

];
