import enLang from './entries/en-US';
import arLang from './entries/ar_SA';
import esLang from './entries/es_ES';

const AppLocale = {
  en: enLang,
  ar: arLang,
  es: esLang,
};

export default AppLocale;
