import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppConst';

const DatabaseProductEvent = React.lazy(() => import('./databaseProductEvent'));
const DatabaseProductVariant = React.lazy(() => import('./databaseProductVariant'));

export const databaseManagementConfig = [
  {
    permittedRole:
      RoutePermittedRole.system_manager,
    path: '/databaseMangement/databaseProductEvent',
    element: <DatabaseProductEvent />,
  },
  {
    permittedRole:
      RoutePermittedRole.system_manager,
    path: '/databaseMangement/DatabaseProductVariant',
    element: <DatabaseProductVariant />,
  },
];
